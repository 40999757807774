import React, { Component, useState } from "react";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import jwt_decode from "jwt-decode";
import axios from "axios";

const BasicSetting = () => {
  const history = useHistory();
  // Token
  const token = localStorage.getItem("jwtToken");
  const decodedToken = jwt_decode(token);
  const userInfo = decodedToken;
  const user_id = userInfo.user_id;

  // site_title, site_logo, site_favicon, admin_login_cover, site_email, support_email, support_number. site_currency, site_timezone

  const [newUser, setNewUser, setNewAuthor = setNewUser] = useState({
    site_title: "",
    site_logo: "",
    // site_favicon: "",
    // admin_login_cover: "",
    site_email: "",
    support_email: "",
    support_number: "",
    site_currency: "",
    site_timezone: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("site_title", newUser.site_title);
    formData.append("site_logo", newUser.site_logo);
    // formData.append("site_favicon", newUser.site_favicon);
    // formData.append("admin_login_cover", newUser.admin_login_cover);
    formData.append("site_email", newUser.site_email);
    formData.append("support_email", newUser.support_email);
    formData.append("support_number", newUser.support_number);
    formData.append("site_currency", newUser.site_currency);
    formData.append("site_timezone", newUser.site_timezone);

    if (newUser.site_title.length === 0) {
      alert("Fillup Name ");
      return;
    }

    if (newUser.site_logo.length === 0) {
      alert("Please Logo Upload");
      return;
    }

    // if (newUser.site_favicon.length === 0) {
    //   alert("Please Favicon Logo Upload");
    //   return;
    // }

    // if (newUser.admin_login_cover.length === 0) {
    //   alert("Please Admin login cover Upload");
    //   return;
    // }

    if (newUser.site_email.length === 0) {
      alert("Please Fillup Email ");
      return;
    }

    if (newUser.support_email.length === 0) {
      alert("Fillup Support Email ");
      return;
    }

    if (newUser.support_number.length === 0) {
      alert("Fillup Support Number ");
      return;
    }

    if (newUser.site_currency.length === 0) {
      alert("Fillup site currency  ");
      return;
    }

    if (newUser.site_timezone.length === 0) {
      alert("Fillup site timezone ");
      return;
    }

    axios
      .post("/api/users/BasicSetting/", formData)
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          alert("Added Successfully");
          history.push("/basic-setting-list");
        } else {
          console.log(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setNewAuthor({ ...newUser, [e.target.name]: e.target.value });
  };

  const handlelogo = (e) => {
    setNewAuthor({ ...newUser, site_logo: e.target.files[0] });
  };

  // const handlesite_favicon = (e) => {
  //   setNewAuthor({ ...newUser, site_favicon: e.target.files[0] });
  // };



  return (
    <>
      <Navbar />

      <Chatbox />

      <HeaderRight />

      <LeftSidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className=" col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Site Setting</h4>

                {/* <Link to="/user-bal-list">
                  <button type="button" classNameName="btn btn-success float-right">
                    User List
                  </button>
                </Link> */}

              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          placeholder="Brand Name"
                          className="form-control"
                          name="site_title"
                          value={newUser.site_title}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">Logo</label>
                      <div className="col-sm-9">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          name="site_logo"
                          className="form-control"
                          onChange={handlelogo}
                        />
                      </div>
                    </div>

                    {/* <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Favicon Logo
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          name="site_favicon"
                          className="form-control"
                          onChange={handlesite_favicon}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Admin Login Cover Banner
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          name="admin_login_cover"
                          className="form-control"
                          onChange={handleadmin_login_cover}
                        />
                      </div>
                    </div> */}

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          placeholder="Site Email"
                          className="form-control"
                          name="site_email"
                          value={newUser.site_email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Support Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          placeholder="Site Support Email"
                          className="form-control"
                          name="support_email"
                          value={newUser.support_email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Support Number
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          placeholder="Site Support Number"
                          className="form-control"
                          name="support_number"
                          value={newUser.support_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Currency</label>
                      <div class="col-sm-9">
                        <select
                          id="country_currency"
                          name="site_currency"
                          class="form-control"
                          // onChange={setdata}
                          onChange={handleChange}
                          required
                        >
                          <option disabled selected>Select One</option>
                          <option value="BDT" label="Pound sterling">
                            BDT
                          </option>
                          <option value="USD" label="US dollar">
                            USD
                          </option>
                          <option value="EUR" label="Euro">
                            EUR
                          </option>
                          <option value="JPY" label="Japanese yen">
                            JPY
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Time Zone</label>
                      <div class="col-sm-9">
                        <select
                          name="site_timezone"
                          id="timezone-offset"
                          class="form-control"
                          onChange={handleChange}
                        >
                          <option disabled selected>
                            
                            Select One
                          </option>
                          
                          <option value="-12:00">
                            (GMT -12:00) Eniwetok, Kwajalein
                          </option>
                          <option value="-11:00">
                            (GMT -11:00) Midway Island, Samoa
                          </option>
                          <option value="-10:00">(GMT -10:00) Hawaii</option>
                          <option value="-09:50">(GMT -9:30) Taiohae</option>
                          <option value="-09:00">(GMT -9:00) Alaska</option>
                          <option value="-08:00">
                            (GMT -8:00) Pacific Time (US &amp; Canada)
                          </option>
                          <option value="-07:00">
                            (GMT -7:00) Mountain Time (US &amp; Canada)
                          </option>
                          <option value="-06:00">
                            (GMT -6:00) Central Time (US &amp; Canada), Mexico
                            City
                          </option>
                          <option value="-05:00">
                            (GMT -5:00) Eastern Time (US &amp; Canada), Bogota,
                            Lima
                          </option>
                          <option value="-04:50">(GMT -4:30) Caracas</option>
                          <option value="-04:00">
                            (GMT -4:00) Atlantic Time (Canada), Caracas, La Paz
                          </option>
                          <option value="-03:50">
                            (GMT -3:30) Newfoundland
                          </option>
                          <option value="-03:00">
                            (GMT -3:00) Brazil, Buenos Aires, Georgetown
                          </option>
                          <option value="-02:00">
                            (GMT -2:00) Mid-Atlantic
                          </option>
                          <option value="-01:00">
                            (GMT -1:00) Azores, Cape Verde Islands
                          </option>
                          <option value="+00:00" >
                            (GMT) Western Europe Time, London, Lisbon,
                            Casablanca
                          </option>
                          <option value="+01:00">
                            (GMT +1:00) Brussels, Copenhagen, Madrid, Paris
                          </option>
                          <option value="+02:00">
                            (GMT +2:00) Kaliningrad, South Africa
                          </option>
                          <option value="+03:00">
                            (GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg
                          </option>
                          <option value="+03:50">(GMT +3:30) Tehran</option>
                          <option value="+04:00">
                            (GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi
                          </option>
                          <option value="+04:50">(GMT +4:30) Kabul</option>
                          <option value="+05:00">
                            (GMT +5:00) Ekaterinburg, Islamabad, Karachi,
                            Tashkent
                          </option>
                          <option value="+05:50">
                            (GMT +5:30) Bombay, Calcutta, Madras, New Delhi
                          </option>
                          <option value="+05:75">
                            (GMT +5:45) Kathmandu, Pokhara
                          </option>
                          <option value="+06:00">
                            (GMT +6:00) Almaty, Dhaka, Colombo
                          </option>
                          <option value="+06:50">
                            (GMT +6:30) Yangon, Mandalay
                          </option>
                          <option value="+07:00">
                            (GMT +7:00) Bangkok, Hanoi, Jakarta
                          </option>
                          <option value="+08:00">
                            (GMT +8:00) Beijing, Perth, Singapore, Hong Kong
                          </option>
                          <option value="+08:75">(GMT +8:45) Eucla</option>
                          <option value="+09:00">
                            (GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk
                          </option>
                          <option value="+09:50">
                            (GMT +9:30) Adelaide, Darwin
                          </option>
                          <option value="+10:00">
                            (GMT +10:00) Eastern Australia, Guam, Vladivostok
                          </option>
                          <option value="+10:50">
                            (GMT +10:30) Lord Howe Island
                          </option>
                          <option value="+11:00">
                            (GMT +11:00) Magadan, Solomon Islands, New Caledonia
                          </option>
                          <option value="+11:50">
                            (GMT +11:30) Norfolk Island
                          </option>
                          <option value="+12:00">
                            (GMT +12:00) Auckland, Wellington, Fiji, Kamchatka
                          </option>
                          <option value="+12:75">
                            (GMT +12:45) Chatham Islands
                          </option>
                          <option value="+13:00">
                            (GMT +13:00) Apia, Nukualofa
                          </option>
                          <option value="+14:00">
                            (GMT +14:00) Line Islands, Tokelau
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-sm-2">
                        <input
                          className="form-control btn btn-primary"
                          type="submit"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BasicSetting;
