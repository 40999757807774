import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import axios from "axios";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import apiConfig from "../apiConfig";

const UserList = () => {
  let history = useHistory();

  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const currentPage = useRef(1);
  const [status, setstatus] = useState(1);

  // Add states for sorting
  const [sortField, setSortField] = useState("currency");
  const [sortOrder, setSortOrder] = useState(-1); // 'asc' or 'desc'

  useEffect(() => {
    getPaginatedUsers();
  }, [searchQuery, limit, sortField, sortOrder]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getPaginatedUsers = () => {
    const search = searchQuery ? `&search=${searchQuery}` : "";
    const status = 1;
    fetch(
      `${apiConfig.baseURL}/api/agent/paginatedgetdataUser?page=${currentPage.current}&limit=${limit}&status=${status}${search}&sortField=${sortField}&sortOrder=${sortOrder}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data.pageCount);
        setData(data.result);
      });
  };

  const handleBlockUnblock = async (userId, action) => {
    try {
      const response = await axios.put(`${apiConfig.baseURL}/api/agent/${action}/${userId}`);
      if (response.status === 200) {
        getPaginatedUsers();
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error during blocking/unblocking:", error);
    }
  };

  const handleSort = (field) => {
    // Toggle sort order if the same field is clicked; otherwise, set default to 'asc'
    if (sortField === field) {
      setSortOrder(sortOrder === -1 ? 1 : -1);
    } else {
      setSortField(field);
      setSortOrder(-1);
    }
  };

  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginatedUsers();
  }

  const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: "active",
  })`
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 0 2rem;

    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
      margin: 0 5px;
    }
    .page-link {
      background: #26b19b;
    }
    li.active a {
      background-color: #0366d6;
      color: white;
    }
    li.disabled a {
      color: grey;
      background-color: #26b19b !important;
    }
  `;

  return (
    <>
      <Navbar />
      <Chatbox />
      <HeaderRight />
      <LeftSidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex flex-wrap align-items-center justify-content-between">
                <h4 className="card-title">User List</h4>
                    &nbsp; &nbsp; &nbsp;
                <div className="col">
                    <select value={limit} onChange={(e) => setLimit(e.target.value)}>
                      <option value="">Sort</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                </div>

                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Search by Name or ID"
                    className="form-control me-2"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ maxWidth: "300px" }}
                  />

                  <Link to="/inactive-user">
                    <button type="button" className="btn btn-danger">
                      Inactive
                    </button>
                  </Link>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th onClick={() => handleSort("user_id")} style={{ cursor: "pointer" }}>
                          User ID {sortField === "user_id" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("first_name")} style={{ cursor: "pointer" }}>
                          Name {sortField === "first_name" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("currency")} style={{ cursor: "pointer" }}>
                          Balance {sortField === "currency" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th>Exposure</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Referrer</th>
                        <th>Promo Code</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((element, id) => (
                        <tr key={id}>
                          <td>{element.user_id}</td>
                          <td>{element.first_name} {element.last_name}</td>
                          <td>{parseFloat(element.currency).toFixed(2)}</td>
                          <td>{element.exposure}</td>
                          <td>{element.email}</td>
                          <td>{element.mobile}</td>
                          <td>{element.refferer}</td>
                          <td>{element.agent_id}</td>
                          <td>
                            <div className="d-flex">
                              <button
                                className={`btn btn-${
                                  element.account_status === "2" ? "danger" : "success"
                                } shadow btn-xs sharp`}
                                onClick={() =>
                                  handleBlockUnblock(
                                    element._id,
                                    element.account_status === "2" ? "unblock" : "block"
                                  )
                                }
                              >
                                {element.account_status === "2" ? (
                                  <i className="fa fa-times"></i>
                                ) : (
                                  <i className="fa fa-check"></i>
                                )}
                              </button>
                              <Link
                                className="edit-link btn btn-primary shadow btn-xs sharp me-1"
                                to={`edit-user/${element._id}`}
                              >
                                <i className="fa fa-pencil"> </i>
                              </Link>
                              <Link
                                title="bet summery"
                                className="edit-link btn btn-info shadow btn-xs sharp me-1"
                                to={`bet-summery/${element._id}`}
                              >
                                <i className="fa fa-clipboard"> </i>
                              </Link>
                              <Link
                                title="bet summery"
                                className="edit-link btn btn-danger shadow btn-xs sharp me-1"
                                to={`exchange-summery/${element._id}`}
                              >
                                <i className="fa fa-pie-chart"> </i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <MyPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    marginPagesDisplayed={2}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserList;
