import React, { Component, useState } from "react";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import jwt_decode from "jwt-decode";
import axios from "axios";
import apiConfig from '../apiConfig'; 

const ProfileManage = () => {

  const history = useHistory();

  // Token
  const token = localStorage.getItem("jwtToken");
  const decodedToken = jwt_decode(token);
  const userInfo = decodedToken;
  const user_id = userInfo.user_id;

  // input field: handle, last_name, country, country_currency, language, image, password, tpin, email, mobile, user_id

  const [inpval, setINP] = useState({
    handle: "",
    last_name: "",
    country: "",
    country_currency: "",
    language: "",

    // image: "",

    email: userInfo.email,
    mobile: userInfo.mobile,
    user_id: userInfo.user_id,
  });

  const setdata = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const addinpdata = async (e) => {
    e.preventDefault();

    const {
      handle,
      last_name,
      country,
      country_currency,
      language,
   
      email,
      mobile,
      user_id,
      // image,
    } = inpval;

    // validation

    if (handle.length == 0 ) {
      alert(
        'First Name Fillup ',
      )
      return
    }
    
    if (last_name.length == 0 ) {
      alert(
        'Last Name Fillup',
      )
      return
    }

    if (country.length == 0 ) {
      alert(
        'country Fillup Field',
      )
      return
    }

    if (country_currency.length == 0  ) {
      alert(
        'Country Currency Fillup',
      )
      return
    }

    if ( language.length == 0 ) {
      alert(
        'Language Fillup Field',
      )
      return
    }

  
    // if (Validator.isEmpty(data.last_name)) {
    //   errors.last_name = "User name cannot be empty";
    // }
  
    // if (Validator.isEmpty(data.country)) {
    //   errors.country = "country cannot be empty";
    // }
  
    // if (Validator.isEmpty(data.country_currency)) {
    //   errors.country_currency = "country currency cannot be empty";
    // }
  
    // if (Validator.isEmpty(data.language)) {
    //   errors.language = "language cannot be empty";
    // }



    // const res = await axios.delete(`/api/users/ProfileManage/${user_id}`, {
    const res = await fetch(`${apiConfig.baseURL}/api/users/ProfileManage/${user_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        handle,
        last_name,
        country,
        country_currency,
        language,
       
        // image,
        email,
        mobile,
        user_id,
      }),
    });

    const data = await res.json();
    console.log(data);

    if (res.status === 200) {
      alert("Update Successfully");
      history.push("/admin");
    } else {
      console.log(res.status);
    }
  };

  // update button

  // const addinpdata = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.delete(
  //       `/api/users/ProfileManage/${user_id}`
  //     );
  //     console.log(response.data); // Optional: Handle success message

  //     if (response.status === 200) {
  //       alert("Update  Successfully");
  //       // window.location.replace('/agent');
  //       // let history = useHistory();
  //       // history.push("/agent");
  //     }
  //   } catch (error) {
  //     console.error(error); // Optional: Handle error
  //   }
  // };

  return (
    <>
      <Navbar />

      <Chatbox />

      <HeaderRight />

      <LeftSidebar />

      <div class="content-body">
        <div class="container-fluid">
          <div class=" col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Profile Manage</h4>

                {/* <Link to="/user-bal-list">
                  <button type="button" className="btn btn-success float-right">
                    User List
                  </button>
                </Link> */}

              </div>
              <div class="card-body">
                <div class="basic-form">
                  <form>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">User Email</label>
                      <div class="col-sm-9">
                        <input
                          disabled
                          type="email"
                          name="email"
                          class="form-control"
                          placeholder="Email"
                          onChange={setdata}
                          value={inpval.email}
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">User Id</label>
                      <div class="col-sm-9">
                        <input
                          disabled
                          type="text"
                          name="user_id"
                          class="form-control"
                          placeholder="User Id"
                          onChange={setdata}
                          value={inpval.user_id}
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Mobile</label>
                      <div class="col-sm-9">
                        <input
                          disabled
                          type="text"
                          name="mobile"
                          class="form-control"
                          placeholder="Mobile Number"
                          onChange={setdata}
                          value={inpval.mobile}
                        />
                      </div>
                    </div>

                    <fieldset>

                      <legend>Update Web Settings</legend>
              

                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">
                          First Name
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            name="handle"
                            class="form-control"
                            placeholder="First Name"
                            onChange={setdata}
                            value={inpval.handle}
                            // value={userInfo.handle}
                            required
                          />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">Last Name</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            name="last_name"
                            class="form-control"
                            placeholder="Last Name"
                            onChange={setdata}
                            value={inpval.last_name}
                            required
                          />
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">Country</label>
                        <div class="col-sm-9">
                          <select
                            id="country"
                            name="country"
                            class="form-control"
                            onChange={setdata}
                            required
                          >
                            <option disabled selected>Select One</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">
                          Country Currency
                        </label>
                        <div class="col-sm-9">
                          <select
                            id="country_currency"
                            name="country_currency"
                            class="form-control"
                            onChange={setdata}
                            required
                          >
                            <option disabled selected>Select One</option>
                              <option value="BDT" label="US dollar">
                                BDT
                              </option>
                              <option value="USD" label="US dollar">
                                USD
                              </option>
                              <option value="EUR" label="Euro">
                                EUR
                              </option>
                              <option value="GBP" label="Pound sterling">
                                GBP
                              </option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">Language</label>
                        <div class="col-sm-9">
                          <select
                            id="language"
                            name="language"
                            class="form-control"
                            onChange={setdata}
                            required
                          >
                            <option disabled selected>
                              Select One
                            </option>
                            <option value="en">
                              English - en_CA
                            </option>
                           
                          </select>
                        </div>
                      </div>

                      {/* <div class="mb-3 row">
                        <label class="col-sm-3 col-form-label">Image</label>
                        <div class="col-sm-9">
                          <input
                            type="file"
                            name="image"
                            class="form-control"
                            placeholder="image"
                            onChange={setdata}
                            value={inpval.image}
                          />
                        </div>
                      </div> */}

                    </fieldset>


                    <input
                      type="hidden"
                      name="user_id"
                      onChange={setdata}
                      value={userInfo.user_id}
                    />

                    <input
                      type="hidden"
                      name="agentEmail"
                      onChange={setdata}
                      value={userInfo.email}
                    />

                    <div class="mb-3 row">
                      <div class="col-sm-10">
                        <button
                          type="submit"
                          name="send"
                          onClick={addinpdata}
                          class="btn btn-primary"
                        >
                          Update
                        </button>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProfileManage;
