import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useRef } from "react";
import styled from "styled-components";
import apiConfig from '../apiConfig';


const ExchangeSummery = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState([]);
  const [totalWin, setTotalWin] = useState(0);
  const [totalBet, setTotalBet] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "createdAt", direction: "desc" });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const params = {};
        if (startDate && endDate) {
          params.startDate = startDate;
          params.endDate = endDate;
        }
        const response = await axios.get(`${apiConfig.baseURL}/api/agent/exchangeSummery/${id}`, { params });
        setGameData(response.data.data || []);
        setTotalWin(response.data.totalWin || 0);
        setTotalBet(response.data.totalBet || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, startDate, endDate]);

  // const formatDate = (isoDate) => {
  //   const date = new Date(isoDate);
  //   return date.toLocaleString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" });
  // };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
    };

    const timeString = date.toLocaleString('en-US', options);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);

    return `${timeString.toLowerCase()} ${day}.${month}.${year}`;
};



  const sortData = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sortedData = [...gameData].sort((a, b) => {
      if (key === "amount") {
        return direction === "asc" ? a.amount - b.amount : b.amount - a.amount;
      }
      return direction === "asc"
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    });
    setGameData(sortedData);
    setSortConfig({ key, direction });
  };

  const totalProfitLoss = (totalWin - totalBet).toFixed(2);

  return (
    <>
    <Navbar />
    <Chatbox />
    <HeaderRight />
    <LeftSidebar />

    <div class="content-body">
      <div class="container-fluid">
          <div class="card col-lg-12">
              <div className="container">
                <h2>Exchange Summary </h2>
                <div>
                  <label>
                    Start Date: <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                  </label>
                  <label>
                  |  End Date: <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                  </label>
                  {/* <button onClick={() => setGameData([])}>Search</button> */} | 
                 

                    <Link
                      to={`/bet-summery/${id}`}
                      className="btn btn-primary shadow btn-xs"
                      style={{ textAlign: "right", float: "right" }}
                    >
                      Casino Bet History
                    </Link>

                </div>
                <div style={{ color:'#60ff00', fontWeight:'900'}}>
                  <strong>Total Profit / Loss: </strong> {totalProfitLoss} | 
                  {/* <strong>Total Profit / Loss: </strong>{totalWin.toFixed(2) - totalBet.toFixed(2) } |  */}
                   <strong>Total Wins: </strong>{totalWin.toFixed(2)} | 
                  <strong>Total Bets: </strong>{totalBet.toFixed(2)}
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th onClick={() => sortData("createdAt")}>Date</th>
                        <th onClick={() => sortData("amount")}>Amount</th>
                        <th>Win/Loss</th>
                        <th>Match name</th>
                        <th>Round Name</th>
                        <th>Odds</th>
                        <th>Yes / No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gameData.length > 0 ? (
                        gameData.map((item, index) => (
                          <tr key={index}>
                                <td className="text-nowrap">{formatDate(item.createdAt)}</td>
                                <td className="text-nowrap">{item.amount}</td>
                                <td className="text-nowrap">{item.status}</td>
                                <td className="text-nowrap">{item.sport_name} ( {item.match_name} ) </td>
                                <td className="text-nowrap">{item.round_name}</td>
                                <td className="text-nowrap">{item.odds}</td>
                                <td className="text-nowrap">{item.back_lay === '1' ? 'Yes' : 'No'} </td>
                              </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
             </div>
           </div>
          </div>
 
    </>
  );
};

export default ExchangeSummery;
