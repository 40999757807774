import React, { useEffect, useState } from "react";

import Navbar from "../frontend/backend/navbar.js";
import Footer from "../frontend/backend/footer.js";
import Chatbox from "../frontend/backend/chatbox.js";
import HeaderRight from "../frontend/backend/header_right.js";
import LeftSidebar from "../frontend/backend/leftSidebar.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import jwt_decode from "jwt-decode";
import axios from "axios";
import apiConfig from '../apiConfig'; 


const BasicSettingEdit = () => {
  const history = useHistory();
  // Token
  const token = localStorage.getItem("jwtToken");
  const decodedToken = jwt_decode(token);
  const userInfo = decodedToken;
  const user_id = userInfo.user_id;
  const { id } = '649159436693727d84be1fc0'; 
  // site_title, site_logo, site_favicon, admin_login_cover, site_email, support_email, support_number. site_currency, site_timezone

  const [newUser, setNewUser, setNewAuthor = setNewUser] = useState({
    site_title: "",
    site_logo: "",
    // site_favicon: "",
    // admin_login_cover: "",
    site_email: "",
    support_email: "",
    support_number: "",
    site_currency: "",
    site_timezone: "",
    notice: "",
  });

  const [existingLogo, setExistingLogo] = useState(null); 

  // Fetch data on component load
  useEffect(() => {
      const  id  = '649159436693727d84be1fc0'; 
        axios
          .get(`${apiConfig.baseURL}/api/users/BasicSetting/${id}`)
          .then((res) => {
            if (res.status === 200) {
              const data = res.data.data;
              setNewUser({
                site_title: data.site_title || "",
                site_logo: "",
                site_email: data.site_email || "",
                support_email: data.support_email || "",
                support_number: data.support_number || "",
                site_currency: data.site_currency || "",
                site_timezone: data.site_timezone || "",
                notice: data.notice_img || "",
              });
              setExistingLogo(data.site_logo); // Save the existing logo URL.
            } else {
              console.error("Failed to fetch settings");
            }
          })
          .catch((err) => {
            console.error(err);
          });
    }, [id]);


  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("site_title", newUser.site_title);
    formData.append("site_logo", newUser.site_logo);
    // formData.append("site_favicon", newUser.site_favicon);
    // formData.append("admin_login_cover", newUser.admin_login_cover);
    formData.append("site_email", newUser.site_email);
    formData.append("support_email", newUser.support_email);
    formData.append("support_number", newUser.support_number);
    formData.append("site_currency", newUser.site_currency);
    formData.append("site_timezone", newUser.site_timezone);
    formData.append("notice", newUser.notice);

    if (newUser.site_title.length === 0) {
      alert("Fillup Name ");
      return;
    }

    if (newUser.site_logo.length === 0) {
      alert("Please Logo Upload");
      return;
    }

    // if (newUser.site_favicon.length === 0) {
    //   alert("Please Favicon Logo Upload");
    //   return;
    // }

    // if (newUser.admin_login_cover.length === 0) {
    //   alert("Please Admin login cover Upload");
    //   return;
    // }

    if (newUser.site_email.length === 0) {
      alert("Please Fillup Email ");
      return;
    }

    if (newUser.support_email.length === 0) {
      alert("Fillup Support Email ");
      return;
    }

    if (newUser.support_number.length === 0) {
      alert("Fillup Support Number ");
      return;
    }

    if (newUser.site_currency.length === 0) {
      alert("Fillup site currency  ");
      return;
    }

    if (newUser.site_timezone.length === 0) {
      alert("Fillup site timezone ");
      return;
    }

    axios
      // .post(`/api/users/BasicSettingUpdate/${id}`, formData)
      .post(`${apiConfig.baseURL}/api/users/BasicSettingUpdate/649159436693727d84be1fc0`, formData)
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          alert("Update Successfully");
          history.push("/basic-setting-list");
        } else {
          console.log(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setNewAuthor({ ...newUser, [e.target.name]: e.target.value });
  };

  const handlelogo = (e) => {
    setNewAuthor({ ...newUser, site_logo: e.target.files[0] });
  };

  // const handlesite_favicon = (e) => {
  //   setNewAuthor({ ...newUser, site_favicon: e.target.files[0] });
  // };


  return (
    <>
      <Navbar />

      <Chatbox />

      <HeaderRight />

      <LeftSidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className=" col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Update: Site Setting</h4>

                <Link to="/basic-setting-list">
                  <button type="button" className="btn btn-success float-right">
                    Back
                  </button>
                </Link>

              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Notice
                      </label>
                      <div className="col-sm-9">
                      <textarea
                      style={{ height: "200px" }}
                      cols={5}
                      rows={10}
                      name="notice"
                      className="form-control"
                      onChange={handleChange}
                      value={newUser.notice}
                    ></textarea>
                                        
                      </div>
                    </div>
              
                    
                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          placeholder="Brand Name"
                          className="form-control"
                          name="site_title"
                          value={newUser.site_title}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">Logo</label>
                      <div className="col-sm-9">
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          name="site_logo"
                          className="form-control"
                          onChange={handlelogo}
                        />
                      </div>
                    </div>


                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          placeholder="Site Email"
                          className="form-control"
                          name="site_email"
                          value={newUser.site_email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Support Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="email"
                          placeholder="Site Support Email"
                          className="form-control"
                          name="support_email"
                          value={newUser.support_email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <label className="col-sm-3 col-form-label">
                        Site Support Number
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          placeholder="Site Support Number"
                          className="form-control"
                          name="support_number"
                          value={newUser.support_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Currency</label>
                      <div class="col-sm-9">
                        <select
                          id="country_currency"
                          name="site_currency"
                          class="form-control"
                          // onChange={setdata}
                          onChange={handleChange}
                          required
                        >
                          <option disabled selected>
                            
                            Select One
                          </option>
                          <option value="BDT" label="BDT dollar">
                            BDT
                          </option>
                          <option value="USD" label="US dollar">
                            USD
                          </option>
                          <option value="EUR" label="Euro">
                            EUR
                          </option>
                        
                        </select>
                      </div>
                    </div>

                    <div class="mb-3 row">
                      <label class="col-sm-3 col-form-label">Time Zone</label>
                      <div class="col-sm-9">
                        <select
                          name="site_timezone"
                          id="timezone-offset"
                          class="form-control"
                          onChange={handleChange}
                        >
                          <option disabled selected>
                            Select One
                          </option>
                          <option value="+06:30">
                            (GMT +6:30) Dhaka, Bangladesh
                          </option>
                       
                        </select>
                      </div>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-sm-2">
                        <input
                          className="form-control btn btn-primary"
                          type="submit" value="Update"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BasicSettingEdit;



